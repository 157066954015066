



























































































































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularIcon from "~/components/OcularIcon.vue"
import House from "~/models/House"
import Offer from "~/models/Offer"
import OfferAmount from "../offers/OfferAmount.vue"
import OfferFormModale from "../offers/OfferForm/OfferFormModale.vue"
import HouseCard from "./HouseCard/HouseCard.vue"

@Component({
  components: {
    OcularIcon,
    OcularAvatar,
    HouseCard,
    OfferAmount,
    OfferFormModale,
  },
})
export default class HouseOffers extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ required: true }) loading!: boolean

  get offers() {
    return this.house?.offers ? this.house.offers : null
  }

  get authorNameList() {
    if (this.offers) {
      const authors = this.offers.map((offer) => offer.owner?.fullname)
      return [...new Set(authors)]
    }
    return null
  }

  createHouseOffer() {
    const houseOfferEditor = this.$refs.houseOfferEditor as OfferFormModale
    houseOfferEditor.open()
  }

  goToOffer(offer: Offer) {
    const houseId = this.$route.params.houseId
    const offerId = offer.id

    document.documentElement.scrollTop = 0
    if (this.$route.name && offerId) {
      const urlComponents = this.$route.name.split("/")
      this.$router.push({
        name: `${urlComponents[0]}/house/offer`,
        params: {
          houseId: houseId.toString(),
          offerId: offerId.toString(),
        },
      })
    }
  }

  offerUpdated() {
    this.$emit("refresh")
  }
}
